<template>
  <div class="map-con">
    <img :src="require('@/assets/images/mapbg.png')" alt="" />
    <div class="middlef-con">
      <div
        id="dtchart"
        class="dtchart"
        :style="{ opacity: mapShow ? '1' : '0' }"
      />
      <!-- <img src="mapbg6.png" id="mapbg" style="display: none" /> -->
    </div>
  </div>
</template>

<script>
import overlayApi from "@/request/api/overlay.js";
import * as echarts from "echarts";
import "echarts-gl"; //echarts 3D插件
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    code: String,
    Level: String,
  },
  data() {
    return {
      mapShow: false,
      regionName: "",
      region: "",
      regionCode: "",
      regionLevel: "",
      mapList: [],
      tkbg: require(`@/assets/images/maptk.png`),
      isShowMap: false, //记录是否点击地图
      dituScrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  async mounted() {
    await this.dictionary();
    this.getMapData();
  },
  methods: {
    async dictionary() {
      await Promise.all([this.paramsdata()]);
    },
    async getInfo() {
      try {
        let info = await loginApi.info();
        this.$store.commit("setUserInfo", info.meshUser);
        if (info.meshUser) {
          let regionName = info.meshUser.regionName;
          if (
            regionName.indexOf("酉阳") != -1 ||
            regionName.indexOf("石柱") != -1 ||
            regionName.indexOf("秀山") != -1 ||
            regionName.indexOf("彭水") != -1
          ) {
            let name1 = regionName.substring(0, 2);
            let name2 = regionName.substr(-1);
            console.log(name1, name2);
            let region = `${name1}${name2}`;
            this.$store.commit("setRegion", region);
          } else {
            this.$store.commit("setRegion", regionName);
          }
        }
      } catch (error) {
        console.log(error.msg);
      }
    },
    paramsdata() {
      this.regionName = this.userInfo.regionName;
      this.region = this.userInfo.region;
      this.regionCode = this.userInfo.region;
      this.regionLevel = this.userInfo.regionLevel;
    },
    async getMapData() {
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
        };
        let res = await overlayApi.homemap(params);
        let list = res.data;
        for (let index = 0; index < list.length; index++) {
          let element = list[index];
          if (element.region == "500242000000") {
            element.printCount = parseInt(element.printCount) + 7139;
          }
          if (element.regionName == "桃花源街道") {
            element.printCount = parseInt(element.printCount) + 59;
          }
          if (element.regionName == "钟多街道") {
            element.printCount = parseInt(element.printCount) + 527;
          }
          if (element.regionName == "龙潭镇") {
            element.printCount = parseInt(element.printCount) + 816;
          }
          if (element.regionName == "麻旺镇") {
            element.printCount = parseInt(element.printCount) + 5452;
          }
          if (element.regionName == "兴隆镇") {
            element.printCount = parseInt(element.printCount) + 11;
          }
          if (element.regionName == "铜鼓镇") {
            element.printCount = parseInt(element.printCount) + 60;
          }
          if (element.regionName == "五福镇") {
            element.printCount = parseInt(element.printCount) + 194;
          }
          if (element.regionName == "偏柏乡") {
            element.printCount = parseInt(element.printCount) + 19;
          }
          if (element.regionName == "庙溪乡") {
            element.printCount = parseInt(element.printCount) + 1;
          }
        }
        let mapList = list.map((item) => {
          item.printCount = item.printCount ? item.printCount : 0;
          return {
            name: item.regionName,
            value: item.printCount,
            region: item.region,
            regionLevel: item.regionLevel,
          };
        });
        this.mapList = mapList.filter((item) => {
          return item;
        });

        await this.$nextTick();

        // let { region,districtCode } = this.userInfo;
        this.mapJson(this.regionCode);
        // if (this.isShowMap) {
        //   if (this.regionCode) {
        //     this.mapJson(this.regionCode);
        //   }
        // } else {
        //   if (!districtCode) {
        //     //重庆市
        //     try {
        //       let axinstance = axios.create({
        //         baseURL: "/",
        //       });
        //       let response = await axinstance.get("chongqing.json");
        //       console.log(response.data);
        //       let jsonCqData = response.data;
        //       // this.loadMap(jsonCqData);
        //       this.getMapData2d(jsonCqData);
        //     } catch (error) {
        //       console.log("error");
        //     }
        //   } else {
        //     this.mapJson(districtCode);
        //   }
        // }
      } catch (error) {
        console.log(error);
      }
    },
    async mapJson(code) {
      if (code == "500000000000") {
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("chongqing.json");
          console.log(response.data);
          let jsonCqData = response.data;
          // this.loadMap(jsonCqData);
          this.getMapData2d(jsonCqData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500230000000") {
        //丰都县
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("fengdu.json");
          console.log(response.data);
          let jsonFdData = response.data;
          this.getMapData2d(jsonFdData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500242000000") {
        //酉阳
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("youyang.json");
          console.log(response.data);
          let jsonYYData = response.data;
          this.getMapData2d(jsonYYData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500114000000") {
        //黔江
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("qianjiang.json");
          console.log(response.data);
          let jsonSZData = response.data;
          this.getMapData2d(jsonSZData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113000000") {
        //巴南
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("bananqu.json");
          console.log(response.data);
          let jsonBNData = response.data;
          this.getMapData2d(jsonBNData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116000000") {
        //江津
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("jiangjinqu.json");
          console.log(response.data);
          let jsonJJData = response.data;
          this.getMapData2d(jsonJJData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119000000") {
        //南川
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("nanchuanqu.json");
          console.log(response.data);
          let jsonNCData = response.data;
          this.getMapData2d(jsonNCData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235000000") {
        //云阳
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("yunyangxian.json");
          console.log(response.data);
          let jsonYYQData = response.data;
          this.getMapData2d(jsonYYQData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233000000") {
        //忠县
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("zhongxian.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500151000000") {
        //铜梁
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("tongliang.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113114000") {
        //东温泉镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("dongwenquanzheng.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113113000") {
        //二圣镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("ershenzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113115000") {
        //姜家镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("jiangjiazhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113118000") {
        //石滩镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("shitanzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113010000") {
        //惠民街道
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("huimingjiedao.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119104000") {
        //大观镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("daguangzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119101000") {
        //南平镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("nanpingzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119110000") {
        //金山镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("jinshangzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119118000") {
        //石溪镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("shixizhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119002000") {
        //南城街道
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("nanchengjiedao.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235135000") {
        //云阳镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("yunyangzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235132000") {
        //高阳镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("gaoyangzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235004000") {
        //盘龙街道
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("panlongjiedao.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235131000") {
        //江口镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("jiangkouzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235003000") {
        //人和街道
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("renhejiedao.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233114000") {
        //新立镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("xinlizhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233122000") {
        //黄金镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("huangjinzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233115000") {
        //双桂镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("shuangguizhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233112000") {
        //马灌镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("maguanzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233106000") {
        //复兴镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("fuxinzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116102000") {
        //吴滩镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("wutanzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116116000") {
        //先锋镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("xianfengzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116108000") {
        //白沙镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("baishazhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116117000") {
        //珞璜镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("luohuangzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116111000") {
        //慈云镇
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("ciyunzhen.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      }
    },
    imgToBase64(e) {
      var t = document.createElement("canvas"),
        a = t.getContext("2d"),
        o = new Image();
      return (
        (o.crossOrigin = "Anonymous"),
        (o.src = e),
        new Promise(function (e, s) {
          o.onload = function () {
            (t.width = 110), (t.height = 150), a.drawImage(o, 0, 0, 110, 150);
            var s = t.toDataURL("image/png");
            (t = null), e(s);
          };
        })
      );
    },
    async getMapData2d(jsonCqData, mapName = "chongqing") {
      let img = this.imgToBase64(require("@/assets/images/mapbs.png"));
      let _this = this;
      if (!this.myChartmap) {
        this.myChartmap = echarts.init(document.getElementById("dtchart"));
      } else {
        // 点击下转市级地图发现边框还是上个地图的区域样式，与新的地图区域不对照，此方法就是清除上个地图的边框样式
        this.myChartmap.clear();
      }
      let data = jsonCqData.features.map((item) => {
        return {
          name: item.properties.name,
        };
      });
      //计算镇级下面的打印数
      let printCounttotal = 0;
      for (let index = 0; index < this.mapList.length; index++) {
        const element = this.mapList[index];
        printCounttotal += element.value;
      }
      let markImg =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAbRJREFUOE+dkj9rU1EYh5/3nHuT3PxpIk0rLQGd1EGkrVBw8CM4Kegn0NmpuHRwEV2c9RMI4qTfoAURQkpxc1KIFdRo0+R6k9xzzitBaquhCL77Az+e9xFtXy6TZIZsZLFzgvWCDcL0+kDVKN4q/kBJSp4sCQx6QXT7fI1KYslyQyE3mMov+PCmUEiVSRxI4kCaeUpVL7qz0mAysmgUEXnDiDNMwjpBG1i+Eds2JT7gbECco1DyU1j0zYV5ylHEvmuRhQfAGobXQA+YJ3AF6JCYezSiLj+co1lxop3VBdJ0mYyXWH1BXZ5QDDmmKISxMjYxfb2Dl+skXKNS2SN1TnT30iKfx48RBizwkCxaI+c+yjmEd8R+k0Q79NjAU2OxeJfUO9G3F0/zabJD0d7ilOvSj7dQWf4tR3SPen6VQdQi9c9YKqxiND8Cy9xkTj+yH2+DLP0bPJxqOKDJo9mpbJK4Dl/YQI9PPZLzCstz6jydlcNtPDf+lHPSO4SvKM2T3/F3AGM9S6broHXEfKdEm6K8nw3gv5ObRj7IDK2JYVg1DI9FPlXrjVK1SnUY6BYCtSRQ7/ufYggOaSK4GS8AAAAASUVORK5CYII=";
      this.mapShow = true;
      await this.$nextTick();
      let resultValueList = this.mapList.map((item) => {
        return {
          name: item.name,
          value: item.value,
          valueStrs: item.value,
          region: item.region,
          regionLevel: item.regionLevel,
        };
      });
      echarts.registerMap(mapName, jsonCqData);
      let option = {
        tooltip: {
          show: true,
          extraCssText: "border:none;background-color: transparent;",
          // 这里可以自定义需要显示的文本内容
          formatter: function (obj) {
            console.log("obj is", obj);
            let { name, valueStrs, value, data } = obj;
            if (name.length > 4) {
              name = name.substring(0, 2);
            }
            console.log(printCounttotal);
            if (_this.regionLevel == 4) {
              value = printCounttotal;
            }
            return `
            <div style='border:none;position: relative;width:149px;height:82px;background-color: transparent;'>
             <img style="width:149px;height:82px" src="${_this.tkbg}" />
             <div style="position:absolute;top:10px;padding:0 20px;">
             <span style="color: #fff5e4;font-size: 14px;">${name}</span>
             <div style="margin-top:20px">
              <span style="color: #fff5e4;font-size: 14px;">${value}</span>
               <span style="color: #fff5e4;font-size: 14px;margin-left:53px">张</span>
             </div>
             </div>
            </div>
            `;
          },
        },
        geo: [
          {
            map: mapName,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "50%"],
            layoutSize: "180%",
            show: true,
            roam: false,
            label: {
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#206BE3",
                borderWidth: 3,
                shadowColor: "#206BE3",
                shadowOffsetY: 0,
                shadowBlur: 0,
                areaColor: "#072356",
              },
            },
            emphasis: {
              areaColor: "#206BE3",
            },
          },
          // {
          //   map: mapName,
          //   aspectScale: 1,
          //   zoom: 0.55,
          //   layoutCenter: ["50%", "50%"],
          //   layoutSize: "180%",
          //   show: true,
          //   roam: false,
          //   label: {
          //     emphasis: {
          //       show: false,
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       borderColor: "3c0f3fb",
          //       borderWidth: 3,
          //       shadowColor: "#8cd3ef",
          //       shadowOffsetY: 10,
          //       shadowBlur: 120,
          //       areaColor: "rgba(29,85,139,.6)",
          //     },
          //   },
          //   emphasis: {
          //     areaColor: "rgba(29,85,139,.6)",
          //   },
          // },
          // 重影
          {
            type: "map",
            map: mapName,
            zlevel: -1,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "50.7%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                // borderWidth: 4,
                // borderColor: {
                //   type: "linear",
                //   x: 0,
                //   y: 0,
                //   x2: 0,
                //   y2: 1,
                //   colorStops: [
                //     {
                //       offset: 0,
                //       color: "#20a2e1", // 0% 处的颜色
                //     },
                //     {
                //       offset: 0.8,
                //       color: "#085473", // 0% 处的颜色
                //     },
                //     {
                //       offset: 1,
                //       color: "#054660", // 50% 处的颜色
                //     },
                //   ],
                // },
                // shadowColor: "rgba(172, 122, 255,0.5)",
                // shadowOffsetY: 5,
                // shadowBlur: 15,
                // areaColor: "rgba(5,21,35,0.1)",
                // borderWidth: 3,
                borderColor: "#206BE3",
                shadowColor: "#206BE3",
                shadowOffsetY: 0,
                shadowBlur: 0,
                areaColor: "#206BE3",
              },
            },
          },
          {
            type: "map",
            map: mapName,
            zlevel: -2,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "51.4%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                // borderWidth: 3,
                borderColor: "#206BE3",
                shadowColor: "#206BE3",
                shadowOffsetY: 0,
                shadowBlur: 0,
                areaColor: "#206BE3",
              },
            },
          },
          {
            type: "map",
            map: mapName,
            zlevel: -3,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "52.1%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                // borderWidth: 3,
                borderColor: "#206BE3",
                shadowColor: "#206BE3",
                shadowOffsetY: 0,
                shadowBlur: 0,
                areaColor: "#206BE3",
              },
            },
          },
        ],
        series: [
          {
            name: "重庆市数据",
            type: "map",
            map: mapName, // 自定义扩展图表类型
            aspectScale: 1,
            zoom: 0.55, // 缩放
            showLegendSymbol: true,
            label: {
              normal: {
                show: true,
                textStyle: { color: "#fff", fontSize: "10px" },
              },
              emphasis: {
                show: true,
                textStyle: { color: "#fff", fontSize: "10px" },
              },
            },
            symbol: "circle",
            // symbolSize: 20,
            itemStyle: {
              normal: {
                areaColor: {
                  // type: "linear",
                  // x: 1200,
                  // y: 0,
                  // x2: 0,
                  // y2: 0,
                  // colorStops: [
                  //   {
                  //     offset: 0,
                  //     color: "#17498d", // 0% 处的颜色
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#3a95fd", // 50% 处的颜色
                  //   },
                  // ],
                  // global: true, // 缺省为 false
                  // image: document.getElementById("mapbg"),
                  // repeat: "repeat",
                },
                borderColor: "#206BE3",
                borderWidth: 1,
              },
              emphasis: {
                show: false,
                color: "#fff",
                areaColor: "#83632a",
              },
            },
            layoutCenter: ["50%", "50%"],
            layoutSize: "180%",
            // markPoint: {
            //   symbol: "image://" + require("@/assets/images/mapbs.png"),
            // },
            data: resultValueList,
            selectedMode: false,
          },
          {
            tooltip: {
              show: false,
            },
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                // color: "#83632a",
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0,255,246,0.1)",
                    },
                    {
                      offset: 0.6,
                      color: "rgba(0,255,246,0.4)",
                    },
                    {
                      offset: 0.85,
                      color: "transparent",
                    },
                    {
                      offset: 1,
                      color: "#FFF",
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            label: {
              normal: {
                color: "#fff",
              },
            },
            showAllSymbol: true,
            symbol: markImg,
            symbolSize: [12, 12],
            symbolRotate: 35,
            symbolOffset: [0, 0],
            data: resultValueList,
            zlevel: 1,
            selectedMode: false,
          },
        ],
      };
      let myChartmap = this.myChartmap;
      myChartmap.setOption(option, true);
      myChartmap.off("click");
      myChartmap.on("click", mapClickHandler); //第一个参数为事件类型，第二个参数为回调函数
      function mapClickHandler(params) {
        try {
          clearInterval(_this.dituScrollTimer);
        } catch (error) {
          console.log(error);
        } //清空定时器
        if (params.componentType === "series" && params.seriesType === "map") {
          //只处理地图的点击事件
          let { name, data } = params;
          _this.$emit("regionchange", data);
          if (!data) {
            if (_this.mapSecondaryShow == 2) {
              //说明是点击了上级地图
              _this.backHand();
              console.log("执行了mapSecondaryShow这里");
            }
            if (_this.userInfo.meshUser.regionLevel < 2) {
              //说明是市级以下 没有数据的时候就需要返回上一级
              _this.backHand();
              console.log("执行了没有数据的时候就需要返回上一级这里");
            }
          } else {
            let { region, regionLevel } = data;
            if (
              region == "500230000000" ||
              region == "500242000000" ||
              region == "500113000000" ||
              region == "500116000000" ||
              region == "500119000000" ||
              region == "500235000000" ||
              region == "500233000000" ||
              region == "500151000000" ||
              region == "500114000000"
            ) {
              if (regionLevel > 3) {
                console.log("regionLevel>3的时候");
                return;
              }
              _this.isShowMap = true;
              _this.regionCode = region;
              _this.regionLevel = 3;
              _this.mapSecondaryShow = 2;
              _this.getMapData();
            }
          }
        }
      }
      // 自动滚动dataZoom的代码
      // let currentStart = 0; // 初始
      function autoScrollDataZoom() {
        // console.log("autoScrollDataZoom called");
        let mapList = _this.mapList;
        if (mapList.length != 0) {
          if (currentStart > mapList.length - 1) {
            myChartmap.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: currentStart - 1,
            });
            myChartmap.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: currentStart,
            });
            currentStart = 0;
          }
        }

        if (currentStart > 0) {
          myChartmap.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: currentStart - 1,
          });
        }
        myChartmap.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: currentStart,
        });
        myChartmap.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: currentStart,
        });
        currentStart += 1;
      }

      // 设置定时器来自动滚动dataZoom
      // if (this.dituScrollTimer) {
      //   clearInterval(this.dituScrollTimer);
      // }
      // this.dituScrollTimer = setInterval(autoScrollDataZoom, 2000); // 1000毫秒（1秒）滚动一次
      myChartmap.on("mouseover", (e) => {
        console.log("地图鼠标移入", e);
        // myChartmap.dispatchAction({
        //   type: "downplay",
        //   seriesIndex: 0,
        //   dataIndex: currentStart - 1,
        // });
        // try {
        //   clearInterval(_this.dituScrollTimer);
        // } catch (error) {
        //   console.log(error);
        // }
      });
      //鼠标移出
      myChartmap.on("globalout", (e) => {
        console.log(e);
        // myChartmap.dispatchAction({
        //   type: "highlight",
        //   seriesIndex: 0,
        //   dataIndex: currentStart,
        // });
        // clearInterval(this.dituScrollTimer);
        // this.dituScrollTimer = setInterval(autoScrollDataZoom, 2000);
      });
    },
    //点击地图上方返回
    backHand() {
      this.isShowMap = false;
      this.paramsdata(); //参数
      this.getMapData();
      let region = this.userInfo.regionName;
      this.$store.commit("setRegion", region);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-con {
  @include flrowjusali();
  position: relative;
  .middlef-con {
    width: 611px;
    position: absolute;
    .dtchart {
      width: 100%;
      height: 413px;
      z-index: 999;
    }
  }
}
</style>
