<template>
  <div class="title">
    <img :src="require('@/assets/images/title.png')" alt="" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include flrowjusali();
  position: relative;
  margin-bottom: 48px;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 96px;
    // height: 21px;
    font-size: 22px;
    font-family: Alimama ShuHeiTi, Alimama ShuHeiTi-Bold;
    font-weight: 700;
    font-style: italic;
    text-align: left;
    color: #e7fcff;
    line-height: 7px;
    letter-spacing: 1.65px;
    text-shadow: NaNpx NaNpx 0px NaNpx #000000;
  }
}
</style>
