import http from "../http.js";

const api = {
  /** 项目信息 */
  getBigScreen: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/bigScreen`,
      data: params,
    });
  },
  /** 地图展示信息 */
  homemap: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/meshAndSubject`,
      data: params,
    });
  },
  //合格证打印数量
  getBottom: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/getBottom`,
      data: params,
    });
  },
  //主体基本数量表
  subjectInfo: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      // url: `/rjtStatisticData/companyPrintStatisticData`,
      url: "/bigScreen/companyPrintStatisticData",
      data: params,
    });
  },
  //主体数据图
  subjectTu: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: "/bigScreen/clickLeftOne",
      data: params,
    });
  },
  checkInfo: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtCheck/queryByPage`,
      data: params,
    });
  },
  //日常巡查
  patroRecord: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtPatrolRecord/queryByPage`,
      data: params,
    });
  },
  //星球数据
  rjtRegion: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtRegion/queryByPage`,
      data: params,
    });
  },
  //领导小组
  meshUser: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtMeshUser/queryByPage`,
      data: params,
    });
  },
  //城市
  region: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtRegion/getRegionOptions`,
      data: params,
    });
  },
  //合格证打印
  printData: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtCertificatePrint/queryByPage`,
      data: params,
    });
  },
  //两品一标
  selectSign: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtSubjectInfo/selectSign`,
      data: params,
    });
  },
  //监管消息
  jgMessage: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtWebMessage/queryByPage`,
      data: params,
    });
  },
  //数据总览
  tionCount: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/certificatePatrolDetectionCount`,
      data: params,
    });
  },
  //合格证每日打印数量
  hgzmrdysl: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/certificateDayPrintCount`,
      data: params,
    });
  },
  //合格证打印数量
  hgzdysl: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/certificateRegionPrintCount`,
      data: params,
    });
  },
  //合格证行业类别打印统计
  hgzhylbdytj: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/certificateTradeTypePrintCount`,
      data: params,
    });
  },
  //两品一标统计
  selectSignCount: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/selectSignCount`,
      data: params,
    });
  },
  //产品检测数
  patrolDetection: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/patrolDetection`,
      data: params,
    });
  },
  //实时汇总
  realTimeDetection: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/realTimeDetection`,
      data: params,
    });
  },
  //每日检测统计 数据趋势
  detectionDayCount: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/detectionDayCount`,
      data: params,
    });
  },
  //合格证打印数
  printCount: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      // url: `/bigScreen/certificateProductPrintCount`,
      url: "/bigScreen/certificateProductPrintCount",
      data: params,
    });
  },
  patrolRecordRank: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/patrolRecordRank`,
      data: params,
    });
  },
  patrolRecordManage: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreen/patrolRecordManage`,
      data: params,
    });
  },
  //企业信息展示评分
  subjectScore: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtSubjectInfo/bigScreen`,
      data: params,
    });
  },
  // 承诺达标
  promiseQualified: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtSubjectInfo/promiseQualified`,
      data: params,
    });
  },
  //企业信息
  subjectInfoShow: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtSubjectInfo/subjectInfoShow`,
      data: params,
    });
  },
  ///
  meshUserTree: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtMeshUser/meshUserTree`,
      data: params,
    });
  },
  //标签信息
  labelInformation: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/labelInformation`,
      data: params,
    });
  },
  //主体信息
  homePageTopVS: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/homePageTopVS`,
      data: params,
    });
  },
  verticalScreen: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/verticalScreenBottom`,
      data: params,
    });
  },
};

export default api;
